<template>
  <div class="timeline-container">
    <div class="timeline">
      <div class="item" :class="getClass(index)" v-for="(value, index) in data" :key="index">
        <div class="item-body">
          <slot :data="value"></slot>
        </div>
        <div class="item-devider">
          <div class="dot date"></div>
          <a href="#" class="dot dot-plus" :title="$t('message.add', {name: ''})" v-if="onAdd" @click.prevent="onAdd(index)">+</a>
          <a href="#" class="dot dot-minus" :title="$t('message.delete', {name: ''})" v-if="onRemove" @click.prevent="onRemove(index)">-</a>
        </div>
      </div>
      <div class="item" v-if="onNew">
        <div class="item-body" style="opacity: 0"></div>
        <div class="item-devider">
          <a href="#" class="dot dot-new" :title="$t('message.add', {name: ''})" @click.prevent="onNew()">+</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Array,
    onRemove: Function,
    onAdd: Function,
    onNew: Function
  },
  data () {
    return {
      slots: []
    }
  },
  methods: {
    getClass (index) {
      if (index % 2 !== 0) {
        return { right: true }
      }
      return {}
    }
  },
  mounted () {
    Object.keys(this.data).forEach(function (id) {
      Object.keys(this.data[id]).forEach(function (key) {
        this.$el.getElementsByClassName(key)[id].innerHTML = this.data[id][key]
      }.bind(this))
    }.bind(this))
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  $color: #3f51b5
  .timeline
    width: 100%
    position: relative
    &::before
      bottom: 0
      content: ""
      height: 100%
      position: absolute
      top: 0
      width: 1px
      background-color: #AD1457
      left: calc(50% - 2px)
      right: initial
    .item
      display: flex
      .item-body
        flex: 1
        height: 230px
        padding: 20px
        box-sizing: border-box
        border: 1px solid $color
        max-width: calc(50% - 48px)
        display: block
        flex-direction: row
        align-items: center
        justify-content: center
        box-shadow: 0px 10px 10px -3px #bbb
      .item-devider
        position: relative
        display: flex
        background-color: transparent
        align-items: center
        justify-content: center
        min-width: 96px
        flex-direction: column
        .dot
          width: 60px
          height: 60px
          background-color: $color
          border-radius: 50%
          display: flex
          align-items: center
          justify-content: center
          text-align: center
          font-size: 7pt
          font-weight: bold
          color: white
          transition: all 0.4s ease-out
          &.dot-new
            font-size: 26pt
            background-color: #558B2F
            color: white
            text-decoration: none
          &.dot-plus
            margin-top: 5px
            width: 30px
            height: 30px
            font-size: 18pt
            background-color: #558B2F
            color: white
            text-decoration: none
          &.dot-new
            &:hover
              transform: scale(1.2)
          &.dot-minus
            margin-top: 5px
            width: 30px
            height: 30px
            font-size: 18pt
            padding: 0
            background-color: #C62828
            color: white
            text-decoration: none
            &:hover
              transform: scale(1.2)
    .item.right
        flex-direction: row-reverse
  @media (max-width: 500px)
    .timeline
      &::before
        left: calc(80% - 1px)
    .timeline
      .item
        margin-bottom: 20px
        .item-body
          max-width: calc(80% - 48px)
          padding: 5px
          height: 250px
      .item.right
        flex-direction: row

</style>
